import React from 'react';
// eslint-disable-next-line
import Icon from '-!svg-react-loader!src/assets/svgs/Logo-white.svg';

export function Header() {
  return (
    <header>
      <div className="h-28 w-full bg-primary opacity-90 flex items-center justify-between px-10">
        <Icon className="w-20 h-20" color="green" />
        <h1 className="text-white text-lg md:text-5xl font-semibold font-montserrat">
          CV. DE BÖSCHULE
        </h1>
        <div />
      </div>
    </header>
  );
}

import React from 'react';
import GoogleMapReact from 'google-map-react';
// eslint-disable-next-line
import Icon from '-!svg-react-loader!src/assets/svgs/Logo.svg';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function AnyReactComponent(props: {
  // eslint-disable-next-line react/no-unused-prop-types
  lat: number;
  // eslint-disable-next-line react/no-unused-prop-types
  lng: number;
}) {
  return (
    <Icon
      style={{
        width: 250,
        height: 250,
      }}
    />
  );
}

export default function Location() {
  return (
    <div className="h-96">
      <GoogleMapReact
        bootstrapURLKeys={{ key: `AIzaSyA_QSHsIRzRz_0-liFUxJtA7n8RFDjyuiw` }}
        defaultCenter={[50.844289103127686, 5.831299219584573]}
        defaultZoom={17}
        yesIWantToUseGoogleMapApiInternals
      >
        <AnyReactComponent
          lat={50.84531332359117}
          // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
          lng={5.83055374732565}
        />
      </GoogleMapReact>
    </div>
  );
}

import React, { PropsWithChildren } from 'react';
import { Bars } from 'react-loader-spinner';

type Args = {
  loading: boolean;
};

type Props = PropsWithChildren<Args>;

export default function Loader({ children, loading }: Props) {
  return (
    <>
      {loading && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Bars color="#ec6669" width={80} />
        </div>
      )}
      {children}
    </>
  );
}
